/* ==========================================================================
   LAYOUT
   ========================================================================== */

#sb-site {
    background-color: transparent;
    padding: ($spacing-unit * 2) 0;
}

.container {
    background: $background-color;
    padding: 0;
    position: relative;
    margin: 0 auto;
    width: $content-width;
    z-index: 10;

    @include media-query($on-palm) {
        background: rgba($background-color, .95);
        width: 100%;
    }
}

/*
   Index
   ========================================================================== */
.home {
    padding-top: $spacing-unit;
}

.no_pagination {
    display: none;
}

.pagination {
    background: $dark-color;
    height: $spacing-unit;
    padding: ($spacing-unit / 4) 0;

    .navigator {
        color: $grey-color-dark !important;
        float: left;
        font-family: $base-font-family-sans;
        font-weight: 700;
        height: $spacing-unit / 2;
        line-height: $spacing-unit / 2;
        padding: 1rem;
        text-align: center;
        text-transform: uppercase;
        width: $spacing-unit;

        @include border-radius(50%);

        i {
            font-size: 32px;
        }
    }

    a.navigator {
        color: $grey-color-light !important;

        &:hover {
            color: $grey-color !important;
        }
    }

    .prev {
        @extend .navigator;
    }

    .next {
        @extend .navigator;
        float: right;
    }

    .disabled {
        @extend .navigator;
        color: $grey-color-dark !important;
    }
}

.post-list {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    margin: 0;
    list-style: none;

    @include flex-wrap(wrap);

    > li {
        border: 10px solid transparent;
        color: $text-color-light;
        flex-grow: 1;
        height: 200px;
        overflow: hidden;
        position: relative;
        width: 46%;

        @include transition($transition-base);

        // &:first-child {
        //     width: 100%;
        // }

        &:last-child {
            margin-bottom: $spacing-unit;
        }

        &:hover {
            border-color: $grey-color-light;
        }

        .post-list-link {
            background: rgba($dark-color, .5);
            bottom: 0;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;

            &:hover {
                background: rgba($dark-color, .25);
            }

            .post-list-info {
                bottom: $spacing-unit / 4;
                padding: 0 ($spacing-unit / 4);
                position: absolute;
                text-shadow: 1px 1px 10px $dark-color;
                text-transform: uppercase;

                .post-list-title {
                    color: $light-color;
                    font-size: 22px;
                    font-style: italic;
                    letter-spacing: 4px;
                    line-height: 135%;
                    margin: 0;
                }

                .post-list-meta {
                    color: darken($grey-color-light, 5%);
                    font-family: $base-font-family-sans;
                    font-size: 11px;
                    font-weight: 700;
                    letter-spacing: 2px;
                    margin: 0;
                }
            }
        }

        .post-list-thumb {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }
}

.rss-subscribe {
    text-align: center;
}

/*
   Archive
   ========================================================================== */
.post-archive-year {
    color: $grey-color-dark;
    font-family: $base-font-family-sans;
    font-weight: 700;
    text-align: center;
}

.post-archive {
    list-style: none;
    margin: 0;
    margin-bottom: $spacing-unit;
    text-align: center;

    > li a {
        font-size: 24px;
    }
}

/*
   Post
   ========================================================================== */
.post-header {
    padding: $spacing-unit 0;
}

.post-reading-time {
    color: $grey-color-dark;
    font-family: $base-font-family-sans;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 3px;
    margin-bottom: 0;
    padding-top: $spacing-unit;
    text-align: center;
    text-transform: uppercase;
}

.post-thumb {
    height: 200px;
    margin: 0 auto $spacing-unit auto;
    object-fit: cover;
    position: relative;
    width: 200px;

    @include border-radius(50%);
}

.post-title {
    font-size: 42px;
    font-style: italic;
    letter-spacing: 8px;
    line-height: 135%;
    margin: 0;
    text-transform: uppercase;
    text-align: center;

    @include media-query($on-laptop) {
        font-size: 36px;
        letter-spacing: 4px;
    }
}

.post-content {
    margin-bottom: $spacing-unit;
    line-height: 2;

    h2 {
        font-size: 32px;

        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 26px;

        @include media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }
}


.post-meta {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    line-height: 2;

    @include flex-wrap(wrap);

    .meta {
        display: inline-block;
        flex-grow: 1;
        padding-bottom: $spacing-unit;
        width: 50%;

        @include media-query($on-laptop) {
            float: none;
            width: 100%;
        }

        &:before {
            background: $brand-color;
            content: "";
            display: block;
            height: 2px;
            margin-bottom: $spacing-unit / 3;
            width: $spacing-unit / 1.5;
        }

        .meta-title {
            color: $text-color;
            font-family: $base-font-family-sans;
            font-size: 13px;
            font-weight: 700;
            letter-spacing: 3px;
            margin: 0;
            padding: 0;
            text-transform: uppercase;
        }

        .meta-content {
            color: $text-color;
            font-size: 16px;
        }
    }
}

.post-share {
    margin-bottom: $spacing-unit;

    .rrssb-buttons li {
        padding: 0 5px 0 0;

        &:last-child {
            padding-right: 0;
        }

        a {
            font-family: $base-font-family-sans;

            @include border-radius(0);
        }
    }
}

#disqus_thread {
    margin-bottom: $spacing-unit;
}

.next-post {
    background: lighten($dark-color, 3%);
    color: $text-color-light;
    height: 200px;
    position: relative;

    .next-post-link {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;

        &:hover {
            background: rgba($dark-color, .25);
        }
    }

    @include media-query($on-laptop) {
        height: auto;
    }

    .next-post-thumb {
        float: left;
        height: 200px;
        margin-right: 30px;
        object-fit: cover;
        width: 200px;

        @include media-query($on-laptop) {
            float: none;
            margin: 0;
            width: 100%;
        }
    }

    .next-post-info {
        padding: 30px;

        @include media-query($on-laptop) {
            padding: 20px;
        }

        .next-story {
            color: $grey-color-dark;
            font-family: $base-font-family-sans;
            font-size: 13px;
            font-weight: 700;
            letter-spacing: 2px;
            margin: 0;
            padding: 0;
            text-transform: uppercase;

            @include transition($transition-base);
        }

        .next-post-title {
            font-size: 22px;
            font-style: italic;
            letter-spacing: 4px;
            line-height: 150%;
            margin: 0;
            padding-top: 10px;
            text-transform: uppercase;

            @include transition($transition-base);

            @include media-query($on-laptop) {
                font-size: 20px;
                letter-spacing: 2px;
                padding-top: 15px;
            }
        }
    }
}
