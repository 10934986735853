@charset "utf-8";

/*
   Variables
   ========================================================================== */
$base-font-family:      "Merriweather", serif;
$base-font-family-sans: "Lato", sans-serif;
$base-font-family-mono: "Source Code Pro", monospace;
$base-font-size:        16px;
$small-font-size:       $base-font-size * 0.875;
$base-line-height:      1;

$dark-color:       #111;
$light-color:      #eee;

$text-color:       $dark-color;
$text-color-light: $light-color;
$background-color: #fdfdfd;
$brand-color:      #e74c3c;
$brand-color-2:    #7D8866;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 35%);
$grey-color-dark:  darken($grey-color, 20%);

$content-width:    700px;
$spacing-unit:     60px;

$on-palm:          799px;
$on-laptop:        800px;

$radius:           6px;
$transition-base:  all 200ms linear;
$transition-nav:   all 500ms linear;

/*
   Mixins
   ========================================================================== */
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius:    $radius;
    -ms-border-radius:     $radius;
    border-radius:         $radius;
}

@mixin flex-wrap($value) {
    -webkit-flex-wrap: $value;
    -moz-flex-wrap:    $value;
    -ms-flex-wrap:     $value;
    flex-wrap:         $value;
}

@mixin transform($args...) {
    -webkit-transform: $args;
    -moz-transform:    $args;
    -o-transform:      $args;
    transform:         $args;
}

@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition:    $args;
    -ms-transition:     $args;
    -o-transition:      $args;
    transition:         $args;
}

/*
   Import
   ========================================================================== */
@import
    "base",
    "layout",
    "sidebar",
    "loader",
    "syntax-highlighting"
;
